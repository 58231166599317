export function fixHeight(elem){
	setTimeout(function(){
		var maxHeight = 0;
		$(elem).css('height','auto');

		$(elem).each(function(){
			if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
		});

		$(elem).height(maxHeight);

	}, 100); 

}