// import important dependencies
import 'bootstrap';
import './ytb_loader.js';
import './fix_height.js';
import 'intersection-observer';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import './carousels/owl-nav.js';
import './carousels/owl-carousel-blog.js';
import './carousels/owl-carousel-cards.js';
import './carousels/owl-carousel-clients.js';
import './carousels/owl-carousel-main.js';
import './carousels/owl-carousel-services-icon.js';
import './carousels/owl-carousel-services.js';
import './carousels/owl-carousel-general.js';

// IE images fix
import objectFitImages from 'object-fit-images';
var $ie_img_contain = $('img.ie-object-fit-contain');
objectFitImages($ie_img_contain);
var $ie_img_cover = $('img.ie-object-fit-cover');
objectFitImages($ie_img_cover);

// Navbar change color when main carousel changed
$(function() {
    var owl = $('.owl-carousel-main');
    owl.owlCarousel();

    owl.on('changed.owl.carousel initialize', function(event) {    
        setTimeout(function() {
            var c = $(".owl-carousel-main").find(".owl-item.center").find(".item").data("color-bg")
        
            if(c == "dark") {
                $("#main_nav").addClass("navbar-black");
                $("#main_nav").removeClass("navbar-white");
                $(".full-screen-carousel").addClass("navbar-black");
                $(".full-screen-carousel").removeClass("navbar-white");
            } else { 
                $("#main_nav").addClass("navbar-white");
                $("#main_nav").removeClass("navbar-black");
                $(".full-screen-carousel").addClass("navbar-white");
                $(".full-screen-carousel").removeClass("navbar-black");
            }

        }); 
    })
});


// Components
import './components/dates.js';
import './components/navbar.js';
import './components/smooth-scroll.js';
import './components/ie.js';
import './components/scrollUp.js';
import './components/cookies.js';


// Bootstrap tooltip activation
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

$(document).ready(function(){
    var maxLength = 150;
    $(".video-box .text").each(function(){
        var myStr = $(this).text();
        if($.trim(myStr).length > maxLength){
            var newStr = myStr.substring(0, maxLength);
            var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
            $(this).empty().html(newStr);
            $(this).append('... <a href="javascript:void(0);" class="read-more">Číst více</a>');
            $(this).append('<span class="more-text">' + removedStr + '</span>');
        }
    });
    $(".read-more").click(function(){
        $(this).siblings(".more-text").contents().unwrap();
        $(this).remove();
    });
});




import SimpleLightbox from "simplelightbox";
import lozad from 'lozad'
const observer = lozad(); 
observer.observe();

let lightbox = new SimpleLightbox('.lightbox');

import vhCheck from 'vh-check';
const test = vhCheck();

