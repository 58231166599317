$(function() {


    $('.owl-carousel-general').owlCarousel({
        loop:true,
        margin:30,
        nav:false,
        autoWidth:true,
        autoplay: true,
    })

});