$(function() {

    $(".owl-carousel-main").find(".item").removeClass("d-none");

	$('.owl-carousel-main').owlCarousel({
        loop:true,
        margin:100,
        nav:false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,
        smartSpeed:600,
        items: 1,
        singleItem: true,
        center: true,
    })

});