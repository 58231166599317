import { fixHeight } from '../fix_height.js';

$(function() {

	var owl_carousel_services = $('.owl-carousel-services');

	owl_carousel_services.owlCarousel({
		loop:true,
		margin:0,
		nav:false,
		dots: false,
		responsive: {
			0: {
				items: 1
			},
			992: {
				items: 2
			},
			1200: {
				items: 3
			}
		}
	});



});


$(window).on('load resize', function () {
    fixHeight('.owl-carousel-services .card');

    setTimeout(function(){
	   	var margin = ($(".wave-box-ie-fix .owl-item").height() - $(".wave-box").height()) / 2;
	    
	    if (margin > 0) {
		    $(".wave-box-ie-fix").css("margin-top", margin + "px")
		    $(".wave-box-ie-fix").css("margin-bottom", margin + "px")
	    }
	}, 100);
});
