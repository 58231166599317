
$(function() {
	let target = null;

	$( ".owl-prev" ).on( "click", function() {
		target = $(this).parents(".owl-nav").data("target");
		let owl = $("." + target);
		owl.owlCarousel();

		owl.trigger('prev.owl.carousel');
	});

	$( ".owl-next" ).on( "click", function() {
		target = $(this).parents(".owl-nav").data("target");
		let owl = $("." + target);
		owl.owlCarousel();

		owl.trigger('next.owl.carousel');
	});


	// reset autoplay timeout after action
	$(".owl-carousel").on('changed.owl.carousel', function(e) {
        $(this).trigger('stop.owl.autoplay');
        $(this).trigger('play.owl.autoplay');
    });

});