$(function() {

	$('.owl-carousel-blog').owlCarousel({
    loop:true,
    margin:15,
    nav:false,
    dots: true,
    center: true,
    responsive:{
        0:{
            items:1
        },
        576:{
            items:2
        },
        992:{
            items:3
        }
    }
})
});