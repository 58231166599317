import { fixHeight } from '../fix_height.js';

$(function() {

	$('.owl-carousel-cards').owlCarousel({
    loop:true,
    margin:0,
    nav:false,
    dots: true,
    center: true,
    responsive:{
        0:{
            items:1
        },
        768:{
            items:2,
            margin: 30
        },
        992:{
            items:3
        }
    }
})
});


$(window).on('load resize', function () {
    fixHeight('.owl-carousel-cards .card');
});
