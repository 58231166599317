$(function() {

	$('.owl-carousel-clients').owlCarousel({
    loop:true,
    margin:15,
    nav:false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed:600,
    responsive:{
        0:{
            items:1
        },
        420:{
            items:2
        },
        576:{
            items:3
        },
        768:{
            items:3
        },
        992:{
            items:4
        },
        1200:{
            items:5
        }
    }
})
});