$(window).on('load resize', function () {
	let navbar_height = $('#main_nav').outerHeight();

	$('.full-screen-carousel').css('top', -navbar_height)
	$('.full-screen-carousel').css('margin-bottom', -navbar_height)
	$('body').css('padding-top', navbar_height)


});

$('li.dropdownxxx').on("click", function () {
	var _this = $(this);
	var dropdown_menu = $(this).find('.dropdown-menu');


	if (window.innerWidth > 992) {
		setTimeout(function(){

			let dropdown_offset = _this.find('.dropdown-menu').offset().left;
			let dropdown_width = _this.find('.dropdown-menu.show').outerWidth();
			let margin = $(window).width() - $("#main_nav .container").innerWidth();

			if((dropdown_offset + dropdown_width) > $(window).width()) {
				offset_right =  (dropdown_offset + dropdown_menu.outerWidth()) - $(".container").width() - margin;
				dropdown_menu.css("left", -offset_right);
		}
		
		}, 10); 


	}


}); 

$( window ).resize(function() {
	$('.dropdown-menu.dropdown-cart').removeClass('show');
	$('.dropdown-menu.dropdown-cart').removeAttr("style");
});

// navbar icon
$(function () {
	$('.navbar-toggler').on('click', function() {
		$(this).toggleClass('active');
		$("#main_nav").toggleClass("opened");
	});
});

$(window).scroll(function() {    
    var scroll = $(window).scrollTop();

    if (scroll >= 10) {
        $("#main_nav").addClass("scrolled");
        $("#main_nav").removeClass("navbar-black");

    } else {
    	$("#main_nav").removeClass("scrolled");
    	var c = $('.owl-carousel-main').find(".owl-item.center").find(".item").data("color-bg")

    	if(c == "dark") {
			$("#main_nav").addClass("navbar-black");
		} else {
			$("#main_nav").addClass("navbar-white");
		}

    }
}); 