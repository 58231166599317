$(function() {

	var owl_carousel_services_icon = $('.owl-carousel-services-icon');

	owl_carousel_services_icon.owlCarousel({
		loop:true,
		margin:40,
		nav:false,
		dots: false,
		center: true,
		autoplay: false,
		autoWidth:true,
		URLhashListener:true,
        startPosition: '#test',
        autoplay:false,
		autoplayTimeout:99999999999,
		autoplayHoverPause:false,
	});	



	if($('.owl-carousel-services-icon').length) {
		let target = $('.owl-carousel-services-icon').find('.item.active').data('target');
		
		if (typeof target !== "undefined") {
			window.location.hash = target
		}
	}


	owl_carousel_services_icon.find(".owl-item.center").find(".icon").addClass("active");

	owl_carousel_services_icon.on('changed.owl.carousel', function(event) {
		setTimeout(
			function() 
			{
				owl_carousel_services_icon.find(".owl-item.center").find(".icon").addClass("active");
				owl_carousel_services_icon.find(".owl-item.center").siblings().find(".icon").removeClass("active");
			}, 200);
	})

});

