function setCookie(key, value, expiry) {
	var expires = new Date();
	// expires after 30 days
	expires.setTime(expires.getTime() + (expiry * 30 * 60 * 60 * 1000));
	document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

function getCookie(key) {
	var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
	return keyValue ? keyValue[2] : null;
}

function eraseCookie(key) {
	var keyValue = getCookie(key);
	setCookie(key, keyValue, '-1');

}

$(function() {

	$( "#cookie-bar #acceptCookies" ).on( "click", function() {
		setCookie('cookies_settings','true','365');
		$('#cookie-bar').fadeOut(500);
	});


	$( "#cookie-bar #cookies_close" ).on( "click", function() {
		$('#cookie-bar').fadeOut(500);
	});


	if (getCookie('cookies_settings') != 'true') {
		$('#cookie-bar').removeClass("d-none");
	}

}); 