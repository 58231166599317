
$( window ).scroll(function(){
		var scroll = $(window).scrollTop();

		if (scroll > $(window).height() / 2) {
			$("#scrollUp").fadeIn();
		} else {
			$("#scrollUp").fadeOut();
		}

	});



$("#scrollUp").click(function() {
  	$("html, body").animate({ scrollTop: 0 }, "slow");
 	return false;
});